// src/components/WineClub/JoinWineClubModal.tsx

import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import API from '../../api/client';
import axios from 'axios';

const publishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

if (!publishableKey) {
  throw new Error('Stripe publishable key is not defined');
}

const stripePromise = loadStripe(publishableKey);

interface JoinWineClubModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const JoinWineClubModal: React.FC<JoinWineClubModalProps> = ({ isOpen, onClose }) => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsProcessing(true);

    try {
      const stripe = await stripePromise;

      const response = await API.post('/api/create-checkout-session/', {
        email,
        name,
        phone,
      });

      const { sessionId } = response.data;

      const result = await stripe?.redirectToCheckout({ sessionId });

      if (result?.error) {
        setErrorMessage(result.error.message || 'An error occurred during redirection.');
      }
    } catch (error) {
      console.error('Error:', error);
      if (axios.isAxiosError(error)) {
        setErrorMessage(error.response?.data?.error || 'An error occurred.');
      } else {
        setErrorMessage('An error occurred. Please try again.');
      }
    } finally {
      setIsProcessing(false);
    }
  };

  const handleClose = () => {
    onClose();
    setEmail('');
    setName('');
    setPhone('');
    setErrorMessage('');
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      {/* Modal Background */}
      <div className="fixed inset-0 bg-black opacity-50" onClick={handleClose}></div>
      {/* Modal Content */}
      <div className="bg-white text-darkBrown rounded-lg p-8 z-50 max-w-md mx-auto relative">
        <button
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
          onClick={handleClose}
        >
          &times;
        </button>
        <h2 className="text-2xl font-satoshi mb-4 text-center">Join the Wine Club</h2>
        <p className="mb-4 text-center">
          Enter your details to proceed. By providing your payment information, you agree to be
          charged <strong>$80.00 per month</strong> starting on the{' '}
          <strong>21st of the month at 9:00 PM PST</strong>. Sign up before this time to receive
          your first pickup next month.
        </p>
        {errorMessage && (
          <div className="mb-4 text-red-500 text-center">{errorMessage}</div>
        )}
        <form onSubmit={handleSubmit} className="space-y-4">
          <input
            type="text"
            placeholder="Full Name"
            className="w-full p-2 border border-gray-300 rounded"
            value={name}
            onChange={e => setName(e.target.value)}
            required
          />
          <input
            type="email"
            placeholder="Email Address"
            className="w-full p-2 border border-gray-300 rounded"
            value={email}
            onChange={e => setEmail(e.target.value)}
            required
          />
          <input
            type="tel"
            placeholder="Phone Number"
            className="w-full p-2 border border-gray-300 rounded"
            value={phone}
            onChange={e => setPhone(e.target.value)}
            required
          />
          <button
            type="submit"
            className="w-full bg-maroon text-cream font-satoshi px-4 py-2 rounded-full"
            disabled={isProcessing}
          >
            {isProcessing ? 'Processing...' : 'Proceed to Payment'}
          </button>
        </form>
        <button
          className="mt-4 text-gray-600 underline w-full text-center"
          onClick={handleClose}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default JoinWineClubModal;