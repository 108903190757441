// src/components/WineClub/HomeHero.tsx

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import homeHeroImage from '../../assets/images/home-hero.jpg'; // Adjust the path if necessary
import JoinWineClubModal from '../WineClub/JoinWineClubModal'; // Adjust the path based on your folder structure

const HomeHero: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="relative w-full h-screen flex items-center text-cream overflow-hidden">
      <img src={homeHeroImage} alt="Home Hero" className="absolute inset-0 object-cover w-full h-full" />
      <div className="absolute inset-0 bg-darkBrown opacity-40 z-0"></div> {/* Dark brown overlay */}
      <div className="relative z-10 p-8 md:p-16 max-w-xl flex flex-col items-center md:items-start justify-center h-full ml-0 md:ml-40">
        <h1 className="text-3xl md:text-4xl lg:text-5xl font-satoshi tracking-wider mb-4 text-center md:text-left w-full">WELCOME TO</h1>
        <h2 className="text-2xl md:text-3xl lg:text-4xl font-recia font-light mb-4 text-center md:text-left w-full">Via Maestra Enoteca</h2>
        <p className="text-lg md:text-xl lg:text-2xl font-satoshi mb-8 text-center md:text-left w-full">Your Community Wine Bar</p>
        <div className="flex flex-col space-y-4 items-center w-full">
          {/* Join Wine Club Button */}
          <button
            className="w-48 py-3 bg-cream text-maroon font-satoshi rounded-full text-center"
            onClick={openModal}
          >
            Join Our Wine Club
          </button>
          {/* Visit Us Link */}
          <Link
            to="/contact-us"
            className="w-48 py-3 bg-cream text-darkBrown font-satoshi rounded-full text-center"
          >
            Visit Us
          </Link>
        </div>
      </div>
      {/* Include the Modal */}
      <JoinWineClubModal isOpen={isModalOpen} onClose={closeModal} />
    </div>
  );
};

export default HomeHero;