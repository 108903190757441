import React from 'react';
import LeafDivider from '../../assets/images/LeafDivider.svg';
import Image1 from '../../assets/images/IMG_0039.jpeg';
import Image2 from '../../assets/images/DSC_0072.jpeg';
import Image3 from '../../assets/images/IMG_0054.jpeg';
import { Link } from 'react-router-dom'; // If you're using react-router for navigation

const CommunityInvolvement: React.FC = () => {
  return (
    <div className="bg-gold pt-16 md:pt-[20vh] pb-[4vh] relative">
      {/* Leaf Divider */}
      <img
        src={LeafDivider}
        alt="Leaf Divider"
        className="absolute top-0 w-full transform  -translate-y-1/4"
      />

      {/* Content Container */}
      <div className="container mx-auto text-center px-4">
        {/* Title */}
        <h2 className="tracking-widest text-3xl mb-4 text-maroon">
          <span className="font-satoshi">COMMUNITY</span>{' '}
          <span className="font-recia">INVOLVEMENT</span>
        </h2>
        {/* Body Text */}
        <p className="font-satoshi text-darkBrown mb-8 max-w-3xl mx-auto">
          Being a part of the Santa Barbara community is at the core of Via Maestra
          Enoteca. We host various events to bring our community together, from wine
          tastings to special gatherings. We are always looking for ways to give back
          and support local initiatives and businesses.
        </p>
      </div>

      {/* Image Trifold */}
      <div className="flex flex-col md:flex-row mb-8">
        <div className="flex-1 overflow-hidden">
          <img
            src={Image1}
            alt="Event 1"
            className="w-full h-64 object-cover"
          />
        </div>
        <div className="flex-1 overflow-hidden">
          <img
            src={Image2}
            alt="Event 2"
            className="w-full h-64 object-cover"
          />
        </div>
        <div className="flex-1 overflow-hidden">
          <img
            src={Image3}
            alt="Event 3"
            className="w-full h-64 object-cover"
          />
        </div>
      </div>

      {/* Button Container */}
      <div className="flex justify-center">
        <Link to="/events">
          <button className="bg-cream text-darkBrown font-satoshi px-6 py-2 rounded-full">
            See Our Events
          </button>
        </Link>
      </div>
    </div>
  );
};

export default CommunityInvolvement;