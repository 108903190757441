import React from 'react';
import grapesImage from '../../assets/images/Grapes.svg';
import WineGlassesInStain from '../../assets/images/WineGlassesInStain.svg';
import GeorgesInWineStain from '../../assets/images/GeorgesInWineStain.svg';

const OurStory: React.FC = () => {
  return (
    <div className="container mx-auto py-16">
      <div className="flex flex-col md:flex-row">
        {/* Left Column */}
        <div className="md:w-1/2 flex flex-col items-center">
          <img src={grapesImage} alt="Grapes" className="mb-8 w-24 md:w-56" />
          <h2 className="text-maroon text-4xl mb-4 flex items-baseline">
            <span className="font-satoshi tracking-widest">OUR&nbsp;</span>
            <span className="font-recia tracking-widest ml-2">STORY</span>
          </h2>
          <div className="w-1/4 h-px bg-olive my-16"></div>
          <p className="text-center font-satoshi text-lg px-4 text-darkBrown mx-4 mb-8 md:mx-16">
            Via Maestra Enoteca is more than just a wine bar; it's a community hub where people come
            together to share good times and great wines. Located just a few doors down from our
            sister restaurant, Via Maestra 42, we encourage you to bring takeout from any local
            eatery and enjoy it with our curated selection of wines. Our inspiration comes from the
            cozy wine bars of Italy and Europe, where friends and family gather to relax and enjoy
            each other's company.
          </p>
        </div>

        {/* Right Column */}
        <div className="md:w-1/2 flex flex-col items-center mt-0 md:mt-[-10vh]">
          <img src={WineGlassesInStain} alt="Wine Glasses" className="w-3/4 mb-[-22vh]" />
          <img src={GeorgesInWineStain} alt="Georges in Wine Stain" className="w-3/4" />
        </div>
      </div>
    </div>
  );
};

export default OurStory;