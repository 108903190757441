import React from 'react';
import { observer } from 'mobx-react-lite';
import contactStore from '../../stores/ContactStore';
import 'react-toastify/dist/ReactToastify.css';

const ContactForm: React.FC = observer(() => {
  return (
    <div className="bg-gold text-darkBrown p-4 sm:p-6 md:p-8 rounded-lg shadow-lg w-full lg:w-1/2 mb-8 lg:mb-0 lg:mr-8 max-w-lg mx-auto lg:max-w-none">
      <div className="text-center mb-4 md:mb-8">
        <h1 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-satoshi mb-2">Contact</h1>
        <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-recia">Us</h2>
      </div>
      <form
        className="space-y-4 sm:space-y-6 md:space-y-8"
        onSubmit={(e) => {
          e.preventDefault();
          contactStore.submitForm();
        }}
      >
        <div>
          <input
            type="text"
            placeholder="Name"
            className="w-full p-2 sm:p-3 border-b-2 border-cream bg-gold text-cream placeholder-cream focus:outline-none"
            value={contactStore.formData.name}
            onChange={(e) => contactStore.setName(e.target.value)}
          />
        </div>
        <div>
          <input
            type="email"
            placeholder="Email"
            className="w-full p-2 sm:p-3 border-b-2 border-cream bg-gold text-cream placeholder-cream focus:outline-none"
            value={contactStore.formData.email}
            onChange={(e) => contactStore.setEmail(e.target.value)}
          />
        </div>
        <div>
          <input
            type="text"
            placeholder="Phone Number"
            className="w-full p-2 sm:p-3 border-b-2 border-cream bg-gold text-cream placeholder-cream focus:outline-none"
            value={contactStore.formData.phone_number}
            onChange={(e) => contactStore.setPhoneNumber(e.target.value)}
          />
        </div>
        <div>
          <textarea
            placeholder="Message"
            className="w-full p-2 sm:p-3 border-b-2 border-cream bg-gold text-cream placeholder-cream focus:outline-none h-32"
            value={contactStore.formData.message}
            onChange={(e) => contactStore.setMessage(e.target.value)}
          ></textarea>
        </div>
        <div className="flex justify-center">
          <button type="submit" className="px-6 sm:px-8 md:px-12 py-2 bg-maroon text-cream font-satoshi rounded-full">
            {contactStore.isLoading ? 'Sending...' : 'Send'}
          </button>
        </div>
      </form>
    </div>
  );
});

export default ContactForm;