import React from 'react';
import { Link } from 'react-router-dom'; // If you're using react-router for navigation
import WreathImage from '../../assets/images/wreath.svg';

const OurCommitmentToQuality: React.FC = () => {
  return (
    <div className="container mx-auto py-16 flex flex-col md:flex-row items-center">
      {/* Left Column */}
      <div className="md:w-1/2 flex justify-center relative">
        {/* Wreath Image */}
        <img
          src={WreathImage}
          alt="Wreath"
          className="w-3/4 md:w-full"
        />
        {/* Text Overlay */}
        <div className="absolute inset-0 flex flex-col items-center justify-center">
          <h2 className="text-maroon font-satoshi tracking-widest text-3xl">
            OUR
          </h2>
          <h2 className="text-maroon font-satoshi tracking-widest text-3xl">
            COMMITMENT
          </h2>
          <h2 className="text-maroon font-recia italic tracking-widest text-2xl mt-2">
            TO QUALITY
          </h2>
        </div>
      </div>

      {/* Right Column */}
      <div className="md:w-1/2 mt-8 md:mt-0 px-6 md:px-12">
        <p className="text-center text-darkBrown text-lg font-satoshi mb-6">
          We take pride in offering a carefully curated selection of wines.{' '}
          <span className="font-bold">Our wine list is updated monthly</span>,
          ensuring that we always provide our guests with the best and most
          exciting options. We believe in quality over quantity, and our
          selection reflects our passion for exceptional wine.
        </p>
        {/* Button */}
        {/* <div className="flex justify-center">
          <Link to="/wine-list">
            <button className="bg-maroon text-cream font-satoshi px-6 py-2 rounded-full">
              Explore our Wine List
            </button>
          </Link>
        </div> */}
      </div>
    </div>
  );
};

export default OurCommitmentToQuality;