import React, { Suspense } from 'react';
import Root from './components/Root';
import { Route, Routes } from 'react-router-dom';
import ErrorBoundary from './components/Root/ErrorBoundary';
import Home from './components/Home';
import WineClub from './components/WineClub';
import ContactUs from './components/ContactUs';
import AboutUs from './components/AboutUs';
import MenuPage from './components/Menu';
import Events from './components/Events';
import WineList from './components/WineList';
import Success from './components/WineClub/Success';
import Cancel from './components/WineClub/Cancel';

const App = () => {
  return (
    <Root>
      <ErrorBoundary>
      {/* Wrap the routes with Suspense and provide a fallback for loading state */}
        <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path="/" element={<Home />}/>
              {/* <Route path="/about-us" element={<AboutUs />}/>  */}
              <Route path="/wine-club" element={<WineClub />}/>
              <Route path="/contact-us" element={<ContactUs/>} />
              <Route path="/menu" element={<MenuPage/>} />
              <Route path="/wine-list" element={<WineList/>} />
              <Route path="/events" element={<Events/>} />
              <Route path="/about-us" element={<AboutUs />}/>
              <Route path="/wine-club/success" element={<Success />} />
              <Route path="/wine-club/cancel" element={<Cancel />} />
              {/* <Route path="/contact-us" element={<ContactUs />}/>
              <Route path="/catering" element={<CateringSection />}/>
              <Route path="/menus" element={<Menu />}>
                <Route path="sb" element={<SantaBarbaraMenu />} />
                <Route path="np" element={<NewburyParkMenu />} />
              </Route>
              <Route path="/locations" element={<Locations />}>
                <Route path="sb" element={<SantaBarbaraLocation />} />
                <Route path="np" element={<NewburyParkLocation />} />
              </Route> */}
            </Routes>
        </Suspense>
        </ErrorBoundary>
    </Root>
  );
};

export default App;