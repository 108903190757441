import { makeAutoObservable } from 'mobx';
import API from '../api/client';
import { toast } from 'react-toastify';

interface ContactFormData {
  name: string;
  email: string;
  phone_number: string;
  message: string;
}

class ContactStore {
  formData: ContactFormData = {
    name: '',
    email: '',
    phone_number: '',
    message: '',
  };
  isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  setName(value: string) {
    this.formData.name = value;
  }

  setEmail(value: string) {
    this.formData.email = value;
  }

  setPhoneNumber(value: string) {
    this.formData.phone_number = value;
  }

  setMessage(value: string) {
    this.formData.message = value;
  }

  async submitForm() {
    this.isLoading = true;

    try {
      const response = await API.post('/api/contact/', this.formData);

      if (response.status === 201) {
        toast.success('Your message has been sent successfully.');
        this.formData = {
          name: '',
          email: '',
          phone_number: '',
          message: '',
        };
      } else {
        toast.error('There was an issue sending your message. Please try again.');
      }
    } catch (err) {
      toast.error('There was an issue sending your message. Please try again.');
    } finally {
      this.isLoading = false;
    }
  }
}

const contactStore = new ContactStore();
export default contactStore;