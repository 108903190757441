import React from 'react';
import grapesImage from '../../assets/images/Grapes.svg';
import wineStainsImage from '../../assets/images/Home_About_Us.svg';
import { Link } from 'react-router-dom'; // Assuming you're using react-router for navigation

const AboutUs: React.FC = () => {
  return (
    <div className="relative bg-cream text-darkBrown py-24 px-8 md:px-16 lg:px-32 text-center">
      <div className="max-w-3xl mx-auto">
        <img src={grapesImage} alt="Grapes" className="mx-auto mb-8 w-56" />
        <p className="text-lg md:text-xl lg:text-2xl mb-8">
          At Via Maestra Enoteca, we believe in creating a space where people can relax, enjoy exceptional wine, and feel at home.
          Inspired by the cozy wine bars of Italy and Europe, our wine bar is a community hub where friends and family gather to
          share good times. Located just a few doors down from our sister restaurant, Via Maestra 42, we invite you to bring in
          takeout from any local eatery and enjoy it with our curated selection of wines.
        </p>
        {/* Centered Button */}
        <div className="flex justify-center">
          <Link to="/about-us">
            <button className="bg-maroon text-cream font-satoshi px-6 py-2 rounded-full">
              About Us
            </button>
          </Link>
        </div>
      </div>
      <img src={wineStainsImage} alt="Wine Stains" className="absolute bottom-0 right-0 w-1/4 h-auto" />
    </div>
  );
};

export default AboutUs;