import React from "react";
import EventsHero from "./EventsHero";
import FeaturedEvent from "./FeaturedEvent";
import WeeklyEvents from "./WeeklyEvents";
import PrivateEvents from "./PrivateEvents";

const Events: React.FC = () => {
    return (
        <>
            <EventsHero/>
            {/* <FeaturedEvent /> */}
            <WeeklyEvents />
            <PrivateEvents />
        </>
    )
}

export default Events;