import React from 'react';
import { ReactComponent as WineStainImage } from '../../assets/images/wine-stain-about-us-page.svg';
import { Link } from 'react-router-dom'; // If you're using react-router for navigation

const PerfectPairings: React.FC = () => {
  return (
    <div className="bg-cream relative overflow-hidden">
      {/* Wine Stain Image */}
      <WineStainImage
        className="w-56 absolute top-0 left-[-4vw] transform hidden md:block"
      />

      <div className="py-16 relative z-10">
        {/* Title */}
        <h2 className="tracking-widest text-3xl mb-4 text-gold flex items-center justify-center">
          <span className="font-satoshi">PERFECT</span>{' '}
          <span className="font-recia ml-2">PAIRINGS</span>
        </h2>
        {/* Body Text */}
        <p className="font-satoshi text-darkBrown mb-8 max-w-3xl mx-auto text-center pb-[4vh]">
          Enhance your wine experience by bringing takeout from our sister
          restaurant, Via Maestra 42. Here are some popular items that pair
          perfectly with our wines:
        </p>

        {/* Trifold Section */}
        <div className="flex flex-col md:flex-row w-full">
          {/* First Card */}
          <div className="flex-1 bg-maroon text-cream py-8 flex flex-col justify-between text-center px-8">
            <h3 className="font-recia text-xl mb-4">Bruschetta di Pomodoro</h3>
            <p className="font-satoshi">
              Chopped tomatoes, basil, mozzarella and garlic on grilled ciabatta
              bread for
            </p>
            <p className="font-satoshi font-bold">$20</p>
          </div>

          {/* Second Card */}
          <div className="flex-1 bg-olive text-cream p-8 flex flex-col justify-between text-center px-8">
            <h3 className="font-recia text-xl mb-4">Cheese Board</h3>
            <p className="font-satoshi">Selection of Italian cheeses</p>
            <p className="font-recia">
              featuring options like Grana Padano, Parmesan, burrata, pesto,
              ciabatta, focaccia, pecorino toscano, goat cheese, smoked
              mozzarella, and Fontina Val d’Aosta cheese.
            </p>
            <p className="font-satoshi font-bold">$25</p>
          </div>

          {/* Third Card */}
          <div className="flex-1 bg-gold text-darkBrown p-8 flex flex-col justify-between text-center px-8">
            <h3 className="font-recia text-xl mb-4">Meat Board</h3>
            <p className="font-satoshi font-bold">Selection of Italian cold cuts</p>
            <p className="font-recia mb-4">
              featuring options like Prosciutto di Parma, Bresaola cured beef,
              mortadella, roasted turkey breast, sopressata, prosciutto cotto,
              pastrami, and Alto Adige Speck Prosciutto.
            </p>
            <p className="font-satoshi font-bold">$25</p>
          </div>
        </div>

        {/* Button */}
        <div className="flex justify-center mt-16">
          <Link to="/menu">
            <button className="bg-maroon text-cream font-satoshi px-6 py-2 rounded-full">
              See Full Menu
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PerfectPairings;