import React from "react";
import HomeHero from "./HomeHero";
import AboutUs from "./AboutUs";
import JoinWineClub from "./JoinWineClub";
import TrifoldTwo from "./TrifoldTwo";

const Home: React.FC = () => {
    return (
        <>
        <HomeHero />
        <AboutUs />
        <JoinWineClub />
        <TrifoldTwo/>
        </>
    )
}

export default Home;