import React from 'react';

const WeeklyEvents: React.FC = () => {
  return (
    <section className="bg-darkBrown text-cream py-16 px-4 sm:px-6 md:px-8 lg:px-16">
      {/* Section Title */}
      <div className="text-center mb-12">
        <h2 className="text-3xl sm:text-4xl lg:text-5xl font-satoshi">Weekly Events</h2>
      </div>

      {/* Event Listings */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        {/* Industry Night - Tuesday */}
        <div className="bg-maroon bg-opacity-70 p-6 rounded-md">
          <h3 className="text-2xl font-bold mb-2">Industry Night</h3>
          <p className="text-lg">Tuesdays 5-7 P.M.</p>
          <p className="mt-2">20% off wine bottles and complimentary pasta.</p>
        </div>

        {/* Local Night - Wednesday */}
        <div className="bg-maroon bg-opacity-70 p-6 rounded-md">
          <h3 className="text-2xl font-bold mb-2">Local Night</h3>
          <p className="text-lg">Wednesdays 5-7 P.M.</p>
          <p className="mt-2">Flight 5 for $25 and a Dinner Special.</p>
        </div>

        {/* Wine Tasting - Thursday */}
        <div className="bg-maroon bg-opacity-70 p-6 rounded-md">
          <h3 className="text-2xl font-bold mb-2">Wine Tasting</h3>
          <p className="text-lg">Thursdays 5-7 P.M.</p>
          <p className="mt-2">Wine tasting with two pasta options.</p>
        </div>

        {/* Bottomless Mimosas - Sunday */}
        <div className="bg-maroon bg-opacity-70 p-6 rounded-md">
          <h3 className="text-2xl font-bold mb-2">Bottomless Mimosas</h3>
          <p className="text-lg">Sundays 1-3 P.M.</p>
          <p className="mt-2">$15 bottomless mimosas.</p>
        </div>
      </div>
    </section>
  );
};

export default WeeklyEvents;