import React from 'react';
import { NavLink } from 'react-router-dom';
import { menuItems } from '../menuItems'; // Adjust the path as needed

const FooterNavLinks: React.FC = () => {
  return (
    <nav className="flex flex-col items-center space-y-4 font-satoshi text-darkBrown">
      {menuItems.map((item) => (
        <NavLink 
          key={item.name} 
          to={item.path} 
          className={({ isActive }) => 
            isActive ? "font-bold text-gold" : "hover:text-gold"
          }
        >
          {item.name}
        </NavLink>
      ))}
    </nav>
  );
};

export default FooterNavLinks;