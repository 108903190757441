import React from 'react';

const EventsHero: React.FC = () => {
  return (
    <section
      className="relative w-full min-h-[60vh] sm:min-h-[70vh] flex items-center justify-center text-cream bg-cover bg-center"
      style={{
        backgroundImage: 'url(https://enoteca-bucket.s3.us-west-2.amazonaws.com/wine-glasses-set-table-part-inviting-dinner-setting.webp)',
      }}
    >
      {/* Container for text and overlay */}
      <div className="absolute bottom-12 sm:bottom-16 md:bottom-24 w-full text-center px-4 sm:px-6 md:px-8 lg:px-16">
        {/* Overlay behind text */}
        <div className="inline-block bg-darkBrown bg-opacity-50 px-4 sm:px-6 py-3 sm:py-4 rounded-md">
          <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-satoshi tracking-wide">
            COMMUNITY <span className="font-recia">EVENTS</span>
          </h1>
          <p className="mt-2 text-base sm:text-lg md:text-xl font-satoshi max-w-xl mx-auto">
            Calendar of upcoming events, such as wine tastings, live music, and special gatherings.
          </p>
        </div>
      </div>
    </section>
  );
};

export default EventsHero;