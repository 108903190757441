import React from 'react';
import { Link } from 'react-router-dom';
import {ReactComponent as HeaderLogo} from '../../../assets/images/Header_Logo.svg';

const Logo: React.FC = () => (
  <Link to="/" className="mr-8 flex items-center">
    <HeaderLogo className="w-20 md:w-28 lg:w-32 h-auto" />
  </Link>
);

export default Logo;