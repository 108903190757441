import React from 'react';
import GeorgesAndNicoleImage from '../../assets/images/GeorgesAndNicole.svg';

const MeetTheOwners: React.FC = () => {
  return (
    <div className="container mx-auto py-16">
      {/* First Row */}
      <div className="flex flex-col md:flex-row items-center">
        {/* Left Column */}
        <div className="md:w-1/2 flex justify-center">
          <img
            src={GeorgesAndNicoleImage}
            alt="Georges and Nicole"
            className="w-3/4"
          />
        </div>

        {/* Right Column */}
        <div className="md:w-1/2 flex flex-col items-center mt-8 md:mt-0">
          {/* Olive Line Above */}
          <div className="h-px bg-olive w-16 md:w-32 mb-4"></div>

          {/* Title */}
          <h2 className="text-maroon font-satoshi tracking-widest text-4xl text-center	pt-8">
            MEET<br/>THE  OWNERS
          </h2>

          {/* Subtitle */}
          <h3 className="font-recia italic text-xl mt-2 text-maroon pb-8">
            Georges & Nicole Bitar
          </h3>

          {/* Olive Line Below */}
          <div className="h-px bg-olive w-16 md:w-32 mt-4"></div>
        </div>
      </div>

      {/* Second Row */}
      <div className="mt-16 px-4">
        {/* Georges Section */}
        <div className="text-center text-darkBrown text-lg">
          {/* Name */}
          <div className="flex justify-center items-baseline">
            <span className="font-satoshi tracking-widest text-xl">
              GEORGES
            </span>
            <span className="font-recia tracking-widest text-xl ml-2">
              BITAR
            </span>
          </div>
          {/* Paragraph */}
          <p className="mt-4 max-w-3xl mx-auto">
            When Georges arrived in the U.S. from Rome, he hit the ground
            running, becoming a much sought-after specialist in wine and spirits.
            His expertise is reflected in our carefully curated wine list and
            spirits selection. Georges’ love for people shines through as he
            welcomes regulars and new friends alike with a smile and a generous
            “Benvenuto!”
          </p>
        </div>

        {/* Olive Green Line */}
        <div className="h-px bg-olive my-8 w-1/6 mx-auto my-24"></div>

        {/* Nicole Section */}
        <div className="text-center text-darkBrown text-lg">
          {/* Name */}
          <div className="flex justify-center items-baseline">
            <span className="font-satoshi tracking-widest text-xl">
              NICOLE
            </span>
            <span className="font-recia tracking-widest text-xl ml-2">
              BITAR
            </span>
          </div>
          {/* Paragraph */}
          <p className="mt-4 max-w-3xl mx-auto">
            When Via Maestra 42 first opened in 2000, Nicole was the first
            manager under the helm of Renato. She brings many years of Italian
            restaurant experience, having worked at some of the best Italian
            restaurants in Santa Barbara and Montecito. Years later, Nicole has
            come full circle and is now co-owner of Via Maestra 42 with her
            husband, Georges. Her light-hearted nature and friendliness make
            everyone feel welcome and part of the “famiglia.” To quote Nicole,
            “we love what we do and are forever grateful for our customers.”
          </p>
        </div>
      </div>
    </div>
  );
};

export default MeetTheOwners;