// src/stores/WineClubStore.ts

import { makeAutoObservable, runInAction } from 'mobx';
import API from '../api/client'; // Your custom axios client

export interface WineClubEntry {
  id: number;
  month_year: string;
  salami: string;
  meat: string;
  cheese: string;
  red_wine: string;
  white_wine: string;
}

class WineClubStore {
  upcomingMonths: WineClubEntry[] = [];
  isLoading = false;
  error = '';

  constructor() {
    makeAutoObservable(this);
  }

  fetchUpcomingMonths = async () => {
    this.isLoading = true;
    try {
      const response = await API.get<WineClubEntry[]>('/api/wineclub/');
      runInAction(() => {
        this.upcomingMonths = response.data;
      });
    } catch (error) {
      runInAction(() => {
        this.error = 'Failed to fetch Wine Club data';
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };
}

const wineClubStore = new WineClubStore();
export default wineClubStore;