// src/components/WineClub/WineClubBenefits.tsx

import React, { useState } from 'react';
import wineBottleSvg from '../../assets/images/wineBottle.svg';
import meatsCheeseSvg from '../../assets/images/meatsCheese.svg';
import benefitsGrapesSvg from '../../assets/images/BenefitsGrapes.svg';
import JoinWineClubModal from './JoinWineClubModal';

const WineClubBenefits: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="bg-gold text-cream font-medium p-8">
      <h2 className="text-3xl md:text-4xl lg:text-5xl font-recia tracking-extraWide mb-4 text-center">
        BENEFITS
      </h2>
      <p className="text-center font-recia mb-8">
        Priority pickup at Via Maestra Enoteca
      </p>
      <div className="max-w-6xl mx-auto flex flex-col md:flex-row justify-center items-center md:space-x-8 space-y-8 md:space-y-0">
        {/* Left Column */}
        <div className="flex flex-col items-center text-center">
          <img src={wineBottleSvg} alt="Wine Bottle" className="w-auto h-16 mb-4" />
          <p className="font-satoshi">
            Monthly selection of <br /><span className="font-bold">2 premium wines</span>
          </p>
        </div>
        {/* Center Column */}
        <div className="flex flex-col items-center text-center">
          <img src={meatsCheeseSvg} alt="Meats and Cheese" className="w-auto h-16 mb-4" />
          <p className="font-satoshi">
            A full pound of Italian meats. <br />Half a pound of Italian cheeses.
          </p>
        </div>
        {/* Right Column */}
        <div className="flex flex-col items-center text-center">
          <img src={benefitsGrapesSvg} alt="Grapes" className="w-auto mt-4 h-12 mb-4" />
          <p className="font-satoshi text-balance">
            Exclusive access to special <br />events and tastings
          </p>
        </div>
      </div>
      {/* Join the Wine Club Button */}
      <div className="text-center mt-8">
        <button
          className="bg-maroon text-cream font-satoshi px-6 py-2 rounded-full"
          onClick={openModal}
        >
          Join the Wine Club
        </button>
      </div>
      {/* Modal */}
      <JoinWineClubModal isOpen={isModalOpen} onClose={closeModal} />
    </div>
  );
};

export default WineClubBenefits;