import React, { useState } from 'react';
import FooterLogo from './FooterLogo';
import FooterNavLinks from './FooterNavLinks';
import { ReactComponent as FooterElements } from '../../../assets/images/Footer_Elements.svg'; // Adjust the path as needed
import { ReactComponent as FooterElement2 } from '../../../assets/images/footer_element2.svg';
import { ReactComponent as FacebookLogo } from '../../../assets/images/fb-logo.svg';
import { ReactComponent as TwitterLogo } from '../../../assets/images/twitter-logo.svg';
import { ReactComponent as InstagramLogo } from '../../../assets/images/instagram-logo.svg';

const Footer: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  return (
    <footer className="bg-cream text-darkBrown px-6 py-8 xl:px-56 xl:py-24 relative z-10">
      <div className="hidden xl:block absolute left-[-4%] top-0 h-full z-0 max-w-[250px]"> {/* Adjusted positioning closer to content on large screens */}
        <FooterElements className="h-full w-auto object-cover" />
      </div>
      <div className="hidden xl:block absolute right-[0%] top-[-30%] w-[15%] h-full z-0 max-w-[200px]"> {/* Adjusted positioning and size to be closer on large screens */}
        <FooterElement2 className="h-full w-auto object-cover" />
      </div>
      <div className="relative z-10 flex flex-col xl:flex-row xl:justify-between">
        <div className="flex flex-col items-center xl:items-start xl:space-y-8">
          <div className="mx-auto mb-8 xl:mb-0">
            <FooterLogo />
          </div>
          {/* <div className="flex flex-col items-center xl:items-start">
            <h3 className="font-paperboard text-xl mb-2">Newsletter</h3>
            <div className="flex items-center w-full max-w-md mb-8">
              <input
                type="email"
                placeholder="Your email..."
                className="flex-grow p-2 rounded-l-full border-2 border-darkBrown focus:outline-none focus:border-gold"
              />
              <button
                className="p-2 bg-darkBrown text-cream rounded-r-full border-2 border-darkBrown hover:bg-gold transition-colors duration-300"
              >
                Subscribe
              </button>
            </div>
          </div> */}
          <div className="flex flex-row mx-auto space-x-6 mb-8">
            {/* <a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer">
              <FacebookLogo className="w-8 h-8" />
            </a> */}
            <a href="https://www.instagram.com/viamaestra_enoteca" target="_blank" rel="noopener noreferrer">
              <InstagramLogo className="w-8 h-8" />
            </a>
            {/* <a href="https://www.twitter.com/" target="_blank" rel="noopener noreferrer">
              <TwitterLogo className="w-8 h-8" />
            </a> */}
          </div>
        </div>
        <div className="flex xl:justify-end justify-center w-full xl:w-auto mt-8 xl:mt-0">
          <FooterNavLinks />
        </div>
      </div>
    </footer>
  );
};

export default Footer; 