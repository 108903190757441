import React from 'react';
import GrabABottleImage from '../../assets/images/GrabABottle.jpg';
import { Link } from 'react-router-dom'; // If you're using react-router for navigation

const OurMission: React.FC = () => {
  return (
    <div className="flex flex-col md:flex-row">
      {/* Left Two-Thirds */}
      <div className="md:w-2/3 relative">
        {/* Image */}
        <img
          src={GrabABottleImage}
          alt="Grab A Bottle"
          className="w-full h-full object-cover"
        />
        {/* Gold Overlay on Middle Third */}
        <div className="hidden md:block absolute top-0 right-0 w-1/2 h-full bg-gold opacity-50"></div>
      </div>

      {/* Right One-Third */}
      <div className="md:w-1/3 bg-maroon text-cream flex flex-col items-center justify-center p-8">
        {/* Title */}
        <div className="text-center">
          <h2 className="tracking-widest text-3xl mb-4">
            <span className="font-satoshi">OUR</span>{' '}
            <span className="font-recia">MISSION</span>
          </h2>
          {/* Paragraph */}
          <p className="font-satoshi mb-8">
            At Via Maestra Enoteca, we are dedicated to creating a space where our
            community can gather, relax, and enjoy exceptional wines. We strive to
            offer an authentic experience inspired by the cozy wine bars of Italy
            and Europe. Our commitment to quality, hospitality, and community is at
            the heart of everything we do.
          </p>
          {/* Button */}
          <Link to="/wine-club">
            <button className="bg-gold text-cream font-satoshi px-6 py-2 rounded-full">
              Join Our Community
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default OurMission;