import React from 'react';
import ContactForm from './ContactForm';
import ContactInfo from './ContactInfo';
import HoursAndLocation from './HoursandLocation';

const ContactUs: React.FC = () => {
  return (
    <div className="relative w-full min-h-screen flex items-center text-cream overflow-hidden">
      <img src={"https://enoteca-bucket.s3.amazonaws.com/contact-us-hero.webp"} alt="Contact Us Hero" className="absolute inset-0 object-cover w-full h-full" />
      <div className="absolute inset-0 bg-darkBrown opacity-40 z-0"></div> {/* Dark brown overlay */}
      
      {/* Contact Form and Contact Info */}
      <div className="relative z-10 p-4 sm:p-6 md:p-8 lg:p-16 max-w-6xl flex flex-col justify-between items-center mx-auto space-y-8 mt-24 md:mt-24 lg:mt-32">
        <div className="flex flex-col lg:flex-row justify-between items-center w-full space-y-8 lg:space-y-0 lg:space-x-8">
          <ContactForm />
          <ContactInfo />
        </div>
        
        {/* Hours and Location - New Row Below */}
        <div className="w-full">
          <HoursAndLocation />
        </div>
      </div>
    </div>
  );
};

export default ContactUs;