import React from 'react';

const PrivateEvents: React.FC = () => {
  return (
    <section className="bg-darkBrown text-cream py-16 px-4 sm:px-6 md:px-8 lg:px-16">
      {/* Section Title */}
      <div className="text-center mb-12">
        <h2 className="text-3xl sm:text-4xl lg:text-5xl font-satoshi">Host Your Private Event</h2>
      </div>

      {/* Content Area */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
        {/* Text Content */}
        <div className="bg-gold p-6 rounded-md">
          <h3 className="text-2xl font-bold mb-4">Create Memorable Experiences</h3>
          <p className="text-lg mb-6">
            Via Maestra Enoteca offers a cozy, intimate setting perfect for hosting private events. Whether you're planning a birthday celebration, corporate gathering, or a special occasion, our wine bar provides the perfect ambiance.
          </p>
          <p className="text-lg mb-6">
            Our flexible space can accommodate small to medium-sized groups, and we offer customized wine selections and catering options to make your event truly special.
          </p>
          <p className="text-lg mb-6">
            Let us help you create unforgettable memories at Via Maestra Enoteca. Contact us today to start planning your event.
          </p>
          <a
            href="/contact-us"
            className="px-8 py-4 bg-maroon text-cream text-lg font-bold rounded-full hover:bg-cream hover:text-maroon transition-colors duration-300"
          >
            Book Your Event
          </a>
        </div>

        {/* Image Section */}
        <div className="w-full h-64 lg:h-full rounded-md overflow-hidden">
          <img
            src="https://enoteca-bucket.s3.us-west-2.amazonaws.com/bounty.webp"
            alt="Private Event Setup"
            className="w-full h-full object-cover"
          />
        </div>
      </div>
    </section>
  );
};

export default PrivateEvents;