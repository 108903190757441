// src/components/WineClub/JoinWineClub.tsx

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import bottleAndGlassImage from '../../assets/images/bottle-and-glass.jpg';
import redWineWomanImage from '../../assets/images/red-wine-woman.jpg';
import JoinWineClubModal from '../WineClub/JoinWineClubModal'; // Import the modal component

const JoinWineClub: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="flex flex-col lg:flex-row w-full h-screen">
      {/* First Segment - Always visible */}
      <div className="bg-maroon text-gold p-16 md:p-24 w-full lg:w-1/3 flex flex-col justify-center items-center">
        <h2 className="text-4xl md:text-5xl lg:text-6xl font-satoshi mb-8 text-center">
          JOIN OUR
          <br />
          <span className="font-recia">WINE CLUB</span>
        </h2>
        <p className="text-md md:text-lg lg:text-xl text-cream mb-16 text-center">
          Gain access to exclusive wines, members-only events, and special discounts. All in addition to our monthly selection of fine Italian meats, cheeses and two bottles of wine. We carefully select our energetic wines to ensure that every sip is an experience to remember.
        </p>
        {/* Buttons */}
        <div className="flex flex-col items-center space-y-4">
          <Link
            to="/wine-club"
            className="px-12 py-4 bg-gold text-darkBrown font-satoshi rounded-full text-center"
          >
            Learn More
          </Link>
          <button
            className="px-12 py-4 bg-cream text-maroon font-satoshi rounded-full text-center"
            onClick={openModal}
          >
            Join Enoteca Wine Club
          </button>
        </div>
      </div>

      {/* Second Segment - Always visible */}
      <div className="relative w-full lg:w-1/3 h-full">
        <img
          src={bottleAndGlassImage}
          alt="Bottle and Glass"
          className="absolute inset-0 object-cover w-full h-full"
        />
        <div className="absolute inset-0 bg-maroon opacity-50"></div> {/* Light maroon overlay */}
      </div>

      {/* Third Segment - Hidden on mobile */}
      <div className="relative w-full lg:w-1/3 h-full hidden lg:block">
        <img
          src={redWineWomanImage}
          alt="Red Wine Woman"
          className="absolute inset-0 object-cover w-full h-full"
        />
      </div>

      {/* Modal */}
      <JoinWineClubModal isOpen={isModalOpen} onClose={closeModal} />
    </div>
  );
};

export default JoinWineClub;