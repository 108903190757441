// src/stores/menuStore.ts

import { makeAutoObservable } from 'mobx';
import API from '../api/client'; // Your custom axios client
import { toast } from 'react-toastify';

export interface Menu {
  id: number;
  name: string;
  categories: Category[];
  items: Item[];
  notes: Note[];
}

export interface Category {
  id: number;
  name: string;
  subcategories: Subcategory[];
  items: Item[];
  notes: Note[];
}

export interface Subcategory {
  id: number;
  name: string;
  items: Item[];
}

export interface Item {
  id: number;
  title: string;
  price: string;
  description: string;
}

export interface Note {
  id: number;
  text: string;
}

class MenuStore {
  wineMenu: Menu | null = null;
  foodMenu: Menu | null = null;
  isLoadingWine = false;
  isLoadingFood = false;
  errorWine = '';
  errorFood = '';

  constructor() {
    makeAutoObservable(this);
  }

  fetchWineMenu = async () => {
    this.isLoadingWine = true;
    try {
      const response = await API.get('/api/menus/wine-list/');
      this.wineMenu = response.data;
    } catch (error) {
      this.errorWine = 'Failed to fetch Wine Menu';
      toast.error(this.errorWine);
    } finally {
      this.isLoadingWine = false;
    }
  };

  fetchFoodMenu = async () => {
    this.isLoadingFood = true;
    try {
      const response = await API.get('/api/menus/food-menu/');
      this.foodMenu = response.data;
    } catch (error) {
      this.errorFood = 'Failed to fetch Food Menu';
      toast.error(this.errorFood);
    } finally {
      this.isLoadingFood = false;
    }
  };
}

const menuStore = new MenuStore();
export default menuStore;