// src/components/WineList.tsx

import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import menuStore from '../../stores/MenuStore';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'; // Import icons for accordion

const WineList: React.FC = observer(() => {
  const [openCategories, setOpenCategories] = useState<number[]>([]);

  useEffect(() => {
    menuStore.fetchWineMenu();
  }, []);

  const { wineMenu, isLoadingWine, errorWine } = menuStore;

  useEffect(() => {
    if (wineMenu) {
      const allCategoryIds = wineMenu.categories.map(category => category.id);
      setOpenCategories(allCategoryIds);
    }
  }, [wineMenu]);

  if (isLoadingWine) return <div>Loading Wine Menu...</div>;
  if (errorWine) return <div>Error: {errorWine}</div>;
  if (!wineMenu) return <div>No Wine Menu Available</div>;

  // Define the order and colors of the sections
  const categoryColors = [
    { bgColor: 'bg-maroon', textColor: 'text-cream' },
    { bgColor: 'bg-gold', textColor: 'text-cream' },
    { bgColor: 'bg-cream', textColor: 'text-darkBrown' },
    { bgColor: 'bg-olive', textColor: 'text-cream' },
    { bgColor: 'bg-darkBrown', textColor: 'text-cream' },
  ];

  const toggleCategory = (id: number) => {
    setOpenCategories(prevState =>
      prevState.includes(id) ? prevState.filter(cid => cid !== id) : [...prevState, id]
    );
  };

  return (
    <div>
      {/* Hero Section */}
      <div className="relative h-[85vh]">
        <img
          src="https://enoteca-bucket.s3.amazonaws.com/BottledOnTheWall.webp"
          alt="Wine List Hero"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="bg-gold bg-opacity-80 w-full py-8 px-4 text-center">
            <h1 className="text-4xl font-satoshi text-cream tracking-extraWide">
              WINE <span className="font-recia">LIST</span>
            </h1>
            <p className="mt-4 text-cream font-satoshi max-w-3xl mx-auto">
              At Via Maestra Enoteca, we offer a carefully curated selection of wines that are updated monthly to ensure variety and quality. Pair your wine with popular takeout options from our sister restaurant, Via Maestra 42, for a complete dining experience.
            </p>
          </div>
        </div>
      </div>

      {/* Accordion */}
      {wineMenu.categories.map((category, index) => {
        const { bgColor, textColor } = categoryColors[index % categoryColors.length];
        const isOpen = openCategories.includes(category.id);
        return (
          <div key={category.id}>
            {/* Accordion Header */}
            <button
              onClick={() => toggleCategory(category.id)}
              className={`w-full flex justify-between items-center py-4 px-6 ${bgColor} ${textColor} font-recia text-2xl`}
            >
              <span>{category.name}</span>
              <span className="text-xl">
                {isOpen ? <FaChevronUp /> : <FaChevronDown />}
              </span>
            </button>
            {/* Accordion Content */}
            {isOpen && (
              <div className={`${bgColor} ${textColor} py-6 px-6`}>
                {/* Wine Items in Two Columns */}
                <div className="max-w-5xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-4">
                  {category.subcategories.length > 0 ? (
                    category.subcategories.map(subcategory => (
                      <div key={subcategory.id} className="mb-8">
                        <h3 className="text-2xl font-recia mb-4 text-center">{subcategory.name}</h3>
                        {subcategory.items.map(item => (
                          <div key={item.id} className="flex justify-between mb-2">
                            <span className="font-satoshi">{item.title}</span>
                            <span className="font-recia">{item.price.replace('$', '')}</span>
                          </div>
                        ))}
                      </div>
                    ))
                  ) : (
                    category.items.map(item => (
                      <div key={item.id} className="flex justify-between mb-2">
                        <span className="font-satoshi">{item.title}</span>
                        <span className="font-recia">{item.price.replace('$', '')}</span>
                      </div>
                    ))
                  )}
                </div>
              </div>
            )}
          </div>
        );
      })}

      {/* Notes */}
      {wineMenu.notes.length > 0 && (
        <div className="py-4 px-4">
          {wineMenu.notes.map(note => (
            <p key={note.id} className="italic text-center font-satoshi">{note.text}</p>
          ))}
        </div>
      )}
    </div>
  );
});

export default WineList;