// src/components/Menu.tsx

import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import menuStore from '../../stores/MenuStore';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'; // Import icons for accordion

const Menu: React.FC = observer(() => {
  const [openCategories, setOpenCategories] = useState<number[]>([]);

  useEffect(() => {
    menuStore.fetchFoodMenu();
  }, []);

  const { foodMenu, isLoadingFood, errorFood } = menuStore;

  useEffect(() => {
    if (foodMenu) {
      const allCategoryIds = foodMenu.categories.map(category => category.id);
      setOpenCategories(allCategoryIds);
    }
  }, [foodMenu]);

  if (isLoadingFood) return <div>Loading Food Menu...</div>;
  if (errorFood) return <div>Error: {errorFood}</div>;
  if (!foodMenu) return <div>No Food Menu Available</div>;

  // Define the order and colors of the sections
  const categoryColors = [
    { bgColor: 'bg-maroon', textColor: 'text-cream' },
    { bgColor: 'bg-gold', textColor: 'text-cream' },
    { bgColor: 'bg-cream', textColor: 'text-darkBrown' },
    { bgColor: 'bg-olive', textColor: 'text-cream' },
    { bgColor: 'bg-darkBrown', textColor: 'text-cream' },
  ];

  const toggleCategory = (id: number) => {
    setOpenCategories(prevState =>
      prevState.includes(id) ? prevState.filter(cid => cid !== id) : [...prevState, id]
    );
  };

  return (
    <div>
      {/* Hero Section */}
      <div className="relative h-[85vh]">
        <img
          src="https://enoteca-bucket.s3.amazonaws.com/MenuHeader.webp"
          alt="Menu Hero"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="bg-maroon bg-opacity-80 w-full py-8 px-4 text-center">
            <h1 className="text-4xl font-satoshi text-cream tracking-extraWide">
              OUR <span className="font-recia">MENU</span>
            </h1>
            <p className="mt-4 text-cream font-satoshi max-w-3xl mx-auto">
              Explore our carefully curated selection of traditional Italian dishes, inspired by the rich culinary heritage of Italy. Our menu is crafted to offer a wide variety of flavors, from classic antipasti to indulgent desserts. Each section reflects our commitment to quality, authenticity, and passion for Italian cuisine.
            </p>
          </div>
        </div>
      </div>

      {/* Accordion */}
      {foodMenu.categories.map((category, index) => {
        const { bgColor, textColor } = categoryColors[index % categoryColors.length];
        const isOpen = openCategories.includes(category.id);
        return (
          <div key={category.id}>
            {/* Accordion Header */}
            <button
              onClick={() => toggleCategory(category.id)}
              className={`w-full flex justify-between items-center py-4 px-6 ${bgColor} ${textColor} font-recia text-2xl`}
            >
              <span>{category.name}</span>
              <span className="text-xl">
                {isOpen ? <FaChevronUp /> : <FaChevronDown />}
              </span>
            </button>
            {/* Accordion Content */}
            {isOpen && (
              <div className={`${bgColor} ${textColor} py-6 px-6`}>
                {/* Menu Items */}
                <div className="max-w-5xl mx-auto">
                  {category.subcategories.length > 0 ? (
                    category.subcategories.map(subcategory => (
                      <div key={subcategory.id} className="mb-8">
                        <h3 className="text-2xl font-recia mb-4 text-center">{subcategory.name}</h3>
                        {subcategory.items.map(item => (
                          <div key={item.id} className="flex justify-between mb-6">
                            <div>
                              <h4 className="font-recia text-xl">{item.title}</h4>
                              {item.description && (
                                <p className="font-satoshi text-base">{item.description}</p>
                              )}
                            </div>
                            <span className="font-recia text-xl">{item.price}</span>
                          </div>
                        ))}
                      </div>
                    ))
                  ) : (
                    category.items.map(item => (
                      <div key={item.id} className="flex justify-between mb-6">
                        <div>
                          <h4 className="font-recia text-xl">{item.title}</h4>
                          {item.description && (
                            <p className="font-satoshi text-base">{item.description}</p>
                          )}
                        </div>
                        <span className="font-recia text-xl">{item.price}</span>
                      </div>
                    ))
                  )}
                </div>
              </div>
            )}
          </div>
        );
      })}

      {/* Notes */}
      {foodMenu.notes.length > 0 && (
        <div className="py-4 px-4 bg-darkBrown text-cream">
          {foodMenu.notes.map(note => (
            <p key={note.id} className="italic text-center font-satoshi">{note.text}</p>
          ))}
        </div>
      )}
    </div>
  );
});

export default Menu;