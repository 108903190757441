// src/components/WineClub/WineClubImageOverlay.tsx

import React from 'react';

const WineClubImageOverlay: React.FC = () => {
  return (
    <div className="relative w-full h-[85vh]">
      {/* Images Grid */}
      <div className="grid grid-cols-1 md:grid-cols-3 h-full">
        <div className="relative">
          <img
            src="https://enoteca-bucket.s3.amazonaws.com/BottledOnTheWall.webp"
            alt="Wine Club 1"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="relative hidden md:block">
          <img
            src="https://enoteca-bucket.s3.amazonaws.com/ManWithBottle.webp"
            alt="Wine Club 2"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="relative hidden md:block">
          <img
            src="https://enoteca-bucket.s3.amazonaws.com/PressForChampagne.webp"
            alt="Wine Club 3"
            className="w-full h-full object-cover"
          />
        </div>
      </div>
      {/* Overlay */}
      <div className="absolute inset-0 flex items-center justify-center top-0 md:top-[15rem]">
        <div className="bg-maroon bg-opacity-80 w-full py-8 px-4 text-center">
          <h1 className="text-4xl font-satoshi text-cream tracking-extraWide">
            WINE CLUB <span className="font-recia">MEMBERSHIP</span>
          </h1>
          <p className="mt-4 text-cream font-satoshi max-w-3xl mx-auto">
            Elevate your experience with our Wine Club, which includes a selection of a full pound of Italian meats,
            1/2 pound of Italian cheeses, and 2 bottles of premium wine each month. Enjoy the finest flavors Italy has to
            offer, available for pickup at Via Maestra Enoteca.
          </p>
        </div>
      </div>
    </div>
  );
};

export default WineClubImageOverlay;