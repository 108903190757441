// src/components/WineClub/UpcomingWineClub.tsx

import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import wineClubStore from '../../stores/WineClubStore';
import { observer } from 'mobx-react-lite';
import JoinWineClubModal from './JoinWineClubModal';

const UpcomingWineClub: React.FC = observer(() => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    wineClubStore.fetchUpcomingMonths();
  }, []);

  const { upcomingMonths, isLoading, error } = wineClubStore;

  if (isLoading)
    return <div className="text-center text-black">Loading Wine Club Information...</div>;
  if (error)
    return <div className="text-center text-black">Error: {error}</div>;
  if (upcomingMonths.length === 0)
    return <div className="text-center text-black">No upcoming Wine Club events.</div>;

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="py-32 px-8 bg-maroon">
      <h2 className="text-3xl md:text-4xl lg:text-5xl tracking-extraWide mb-8 text-center text-cream">
        <span className="font-satoshi">UPCOMING WINE </span>
        <br />
        <span className="font-recia">CLUB MONTHS</span>
      </h2>
      <p className="font-satoshi font-regular text-cream text-balance text-center pb-16 max-w-3xl mx-auto">
        Our Wine Club offers an energetic selection each month, chosen from some of the hundreds of
        wines we taste each week, and adding by varietal. This provides you with a half pound of
        Italian meats, a half pound of Italian cheeses, and two bottles of premium wine. Taste the
        passion and craftsmanship that goes into every bottle and slice, with new varietals and
        flavors to explore.
        <br />
        <br />
        The Wine Club selections are available for pickup during the third week of each month, on or
        after Tuesday.
      </p>
      <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-8">
        {upcomingMonths.map((month, index) => {
          const headingBgColor = index % 2 === 0 ? 'bg-gold' : 'bg-olive';
          return (
            <motion.div
              key={month.month_year}
              className="bg-cream shadow-md flex flex-col"
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              {/* Card Header */}
              <div className={`${headingBgColor} px-8 py-2 text-start text-cream`}>
                <h3 className="font-recia text-xl font-regular">
                  {month.month_year.split(' ')[0]}{' '}
                  <span className="font-recia text-sm">{month.month_year.split(' ')[1]}</span>
                </h3>
              </div>
              {/* Card Body */}
              <div className="px-6 py-4 text-darkBrown flex-1">
                <div className="text-sm md:text-base space-y-2">
                  <p>
                    <span className="font-bold">Salami:</span> {month.salami}
                  </p>
                  <p>
                    <span className="font-bold">Italian Meat:</span> {month.meat}
                  </p>
                  <p>
                    <span className="font-bold">Italian Cheese:</span> {month.cheese}
                  </p>
                  <p>
                    <span className="font-bold">Red Wine:</span> {month.red_wine}
                  </p>
                  <p>
                    <span className="font-bold">White Wine:</span> {month.white_wine}
                  </p>
                </div>
              </div>
            </motion.div>
          );
        })}
      </div>
      {/* Call to Action Section */}
      <div className="text-center mt-16">
        <h3 className="text-2xl md:text-3xl font-recia text-cream mb-4">
          Only $80 a month! Sign Up Today.
        </h3>
        <button
          className="bg-gold text-darkBrown font-satoshi px-6 py-2 rounded-full"
          onClick={openModal}
        >
          Join the Wine Club
        </button>
      </div>
      {/* Modal */}
      <JoinWineClubModal isOpen={isModalOpen} onClose={closeModal} />
    </div>
  );
});

export default UpcomingWineClub;