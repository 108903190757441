import React from 'react';
import OurStory from './OurStory';
import MeetTheOwners from './MeetTheOwners';
import OurMission from './OurMission';
import OurCommitmentToQuality from './OurCommitmentToQuality';
import CommunityInvolvement from './CommunityInvolvement';
import PerfectPairings from './PerfectPairings';

const AboutUs: React.FC = () => {
  return (
    <div className='pt-[15vh] bg-cream'>
      {/* Other sections */}
      <div className='bg-cream'>
        <OurStory />
        <MeetTheOwners />
      </div>
      <OurMission />
      <OurCommitmentToQuality />
      <CommunityInvolvement/>
      <PerfectPairings />
      {/* Future sections */}
    </div>
  );
};

export default AboutUs;