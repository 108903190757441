import React from 'react';
import { ReactComponent as FacebookLogo } from '../../assets/images/fb-light.svg';
import { ReactComponent as TwitterLogo } from '../../assets/images/twitter-light.svg';
import { ReactComponent as InstagramLogo } from '../../assets/images/insta-light.svg';

const ContactInfo: React.FC = () => {
  return (
    <div className="text-cream mt-8 lg:mt-0 w-full lg:w-1/2 lg:ml-8">
      <div className="text-center">
        <p className="text-xl sm:text-2xl md:text-3xl lg:text-4xl tracking-widest font-satoshi font-medium mb-2">CALL US AT</p>
        <p className="text-xl sm:text-2xl md:text-3xl lg:text-4xl tracking-widest font-satoshi font-medium mb-4">(805) 902-4560</p>
      </div>
      <p className="text-sm sm:text-md md:text-lg lg:text-xl font-recia mb-4 sm:mb-6 md:mb-8 text-center">
        for private events, new bottles, or information.
      </p>
      <p className="text-sm sm:text-md md:text-lg lg:text-xl text-center mb-4 sm:mb-6 md:mb-8">
        viamaestrageorges@gmail.com
      </p>
      <div className="flex justify-center space-x-4 mt-4">
        {/* <a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer">
          <FacebookLogo className="w-6 h-6 sm:w-8 sm:h-8" />
        </a> */}
        <a href="https://www.instagram.com/viamaestra_enoteca" target="_blank" rel="noopener noreferrer">
          <InstagramLogo className="w-8 h-8 sm:w-10 sm:h-10" />
        </a>
        {/* <a href="https://www.twitter.com/" target="_blank" rel="noopener noreferrer">
          <TwitterLogo className="w-6 h-6 sm:w-8 sm:h-8" />
        </a> */}
      </div>
    </div>
  );
};

export default ContactInfo;