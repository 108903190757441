// src/components/WineClub/TrifoldTwo.tsx

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import olivesImage from '../../assets/images/olives-in-bowl.jpg';
import rosesAppetizerImage from '../../assets/images/roses-appetizer.jpg';
import JoinWineClubModal from '../WineClub/JoinWineClubModal'; // Import the modal component

const TrifoldTwo: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="flex flex-col lg:flex-row w-full h-screen">
      {/* First Segment - Hidden on mobile */}
      <div className="relative w-full lg:w-1/3 h-full hidden lg:block">
        <img
          src={olivesImage}
          alt="Olives"
          className="absolute inset-0 object-cover w-full h-full"
        />
      </div>

      {/* Second Segment - Always visible */}
      <div className="relative w-full lg:w-1/3 h-full">
        <img
          src={rosesAppetizerImage}
          alt="Roses Appetizer"
          className="absolute inset-0 object-cover w-full h-full"
        />
        <div className="absolute inset-0 bg-olive opacity-50"></div> {/* Olive overlay */}
        <div className="absolute inset-0 flex items-center justify-center p-4">
          <p className="text-md md:text-lg lg:text-xl text-cream text-center font-recia">
            Enjoy the finest flavors that Italy has to offer by joining our Wine Club.
          </p>
        </div>
      </div>

      {/* Third Segment - Always visible */}
      <div className="bg-olive text-cream p-16 md:p-24 w-full lg:w-1/3 flex flex-col justify-center items-center">
        <p className="text-lg md:text-xl lg:text-2xl text-center mb-8">
          Elevate your experience with our Wine Club, which includes a selection of a full pound of Italian meats, a half pound of cheese, and two bottles of premium Italian wine each month for just $80.
        </p>
        {/* Buttons */}
        <div className="flex flex-col items-center space-y-4">
          <Link
            to="/wine-club"
            className="py-3 px-6 bg-gold text-darkBrown font-satoshi rounded-full text-center transition-transform transform hover:scale-105"
          >
            Learn More
          </Link>
          <button
            className="py-3 px-6 bg-maroon text-cream font-satoshi rounded-full text-center transition-transform transform hover:scale-105"
            onClick={openModal}
          >
            Join Enoteca Wine Club
          </button>
        </div>
      </div>

      {/* Modal */}
      <JoinWineClubModal isOpen={isModalOpen} onClose={closeModal} />
    </div>
  );
};

export default TrifoldTwo;