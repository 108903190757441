import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as LogoImage } from '../../../assets/images/Footer_Logo.svg'; // Update the logo path as needed

const FooterLogo: React.FC = () => (
  <Link to="/" className="mr-8">
    <div className="relative w-24 md:w-32 lg:w-36 h-auto"> {/* Adjust width as needed */}
      <LogoImage className="w-full h-full" />
    </div>
  </Link>
);

export default FooterLogo;