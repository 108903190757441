import React from 'react';
import { NavLink } from 'react-router-dom';
import { motion } from 'framer-motion';
import { menuItems } from '../menuItems';

const NavLinks: React.FC = () => {

  return (
    <nav className="hidden xl:flex items-center space-x-8 ml-auto font-satoshi text-cream relative">
      {menuItems.map((item) => (
        <div key={item.name} className="relative">
          <NavLink to={item.path} className="relative">
            {({ isActive }) => (
              <div className="relative flex flex-col items-center">
                <span 
                  className={`relative z-10 ${isActive ? 'font-bold' : ''}`} 
                >
                  {item.name}
                </span>
                {isActive? (
                  <motion.div
                    layoutId="half-circle"
                    className="absolute bottom-0 w-7 h-3 bg-cream rounded-t-full"
                    style={{ bottom: '-20px' }} // Adjust for vertical spacing
                    transition={{ duration: 0.3 }}
                  />
                ) : <></>}
              </div>
            )}
          </NavLink>
        </div>
      ))}
      <div
        className="absolute left-[-10%] right-[-10%] bottom-[-20px] h-0.5 bg-cream" // Adjust bottom for vertical spacing
      />
    </nav>
  );
};

export default NavLinks;