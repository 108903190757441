// src/components/WineClub/index.tsx

import React from 'react';
import WineClubImageOverlay from './WineClubImageOverlay';
import WineClubBenefits from './WineClubBenefits';
import UpcomingWineClub from './UpcomingWineClub';

const WineClub: React.FC = () => {
  return (
    <div className="w-full">
      <WineClubImageOverlay />
      <WineClubBenefits />
      <UpcomingWineClub />
    </div>
  );
};

export default WineClub;